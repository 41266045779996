import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";

import "moment/locale/it";
import moment from "moment";
moment.locale("it");

import "@/assets/styles/tailwind.css";
//import "@/assets/styles/fonts.css";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faHashtag,
  faStar as faStarSolid,
  faGlobe,
  faStarHalfAlt,
  faQuoteLeft,
  faRss,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  faStar as faStarRegular,
  faCalendarAlt,
} from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faHashtag,
  faStarSolid,
  faStarRegular,
  faStarHalfAlt,
  faGlobe,
  faQuoteLeft,
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faGoogle,
  faCalendarAlt,
  faRss,
  faMapMarkerAlt
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

axios.defaults.headers.get.Accept = "application/json";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
