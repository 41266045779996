import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    //anycontent
    {
      name: "home",
      path: "/",
      component: () => import(`@/views/Home.vue`),
    },
    {
      name: "feed",
      path: "/feed/:id",
      props: (route) => ({ id: route.params.id }),
      component: () => import(`@/views/feed/Feed.vue`),
    },
    //test
    {
      name: "test-text-element",
      path: "/test/textelement/",
      component: () => import(`@/views/test/TestTextElement.vue`),
    },
    {
      name: "test-text-to-speech",
      path: "/test/tts/",
      component: () => import(`@/views/test/TestTextToSpeech.vue`),
    },
    //old player integration
    {
      name: "web",
      path: "/players/web/",
      component: () => import(`@/views/players/Web.vue`),
    },
    {
      name: "brightsign",
      path: "/socialslide/brightsign/:version",
      props: (route) => ({ version: route.params.version }),
      component: () => import(`@/views/socialslide/BrightSign.vue`),
    },
  ],
});

export default router;
