<template>
  <div>
    <router-view :key="$route.path"></router-view>
    <!-- render icon invisible for text adapting component -->
    <div id="icon-rendered" :style="[iconStyle]">
      <font-awesome-icon :icon="['fas', 'star']"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      iconStyle: {
        visibility: "hidden",
        top: "1%",
        left: "1%",
        width: "1%",
        height: "1%",
      },
    };
  },
  created: function() {},
};
</script>
